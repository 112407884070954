import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Button from "./Button"

const useStyles = makeStyles(theme => ({
  button: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const SubmitButton = props => {
  const classes = useStyles()
  return <Button type="submit" rootClassName={classes.button} {...props} />
}

export default SubmitButton

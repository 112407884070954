import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import MuiButton from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import { makeStyles } from "@material-ui/styles"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
  },
  wrapper: {
    position: "relative",
  },
  fullWidth: {
    width: "100%",
  },
  buttonProgress: {
    color: theme.palette.primary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}))

const Button = ({
  children,
  rootClassName,
  wrapperClassName,
  loading,
  ...props
}) => {
  const classes = useStyles()

  return (
    <div className={clsx(classes.root, rootClassName)}>
      <div
        className={clsx(
          classes.wrapper,
          {
            [classes.fullWidth]: props.fullWidth,
          },
          wrapperClassName
        )}
      >
        <MuiButton {...props}>{children}</MuiButton>
        {loading && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
      </div>
    </div>
  )
}

Button.propTypes = {
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  rootClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  loading: PropTypes.bool,
}

Button.defaultProps = {
  fullWidth: false,
  loading: false,
}

export default Button

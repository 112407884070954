// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-forgot-password-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-index-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-reset-password-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-sign-in-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/sign-in.js" /* webpackChunkName: "component---src-pages-sign-in-js" */),
  "component---src-pages-sign-up-js": () => import("/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/src/pages/sign-up.js" /* webpackChunkName: "component---src-pages-sign-up-js" */)
}


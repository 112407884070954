import React from "react"
import { ApolloProvider } from "react-apollo"
import { Provider } from "react-redux"

import { ApolloProvider as ApolloHooksProvider } from "@apollo/react-hooks"
import ThemeProvider from "@material-ui/styles/ThemeProvider"

import { createApolloClient } from "./graphql"
import { createStore } from "./state"
import theme from "./theme"

// eslint-disable-next-line react/prop-types
const wrapRootElement = ({ element }) => {
  const store = createStore()
  const apolloClient = createApolloClient()
  return (
    <Provider store={store}>
      <ApolloProvider client={apolloClient}>
        <ApolloHooksProvider client={apolloClient}>
          <ThemeProvider theme={theme}>{element}</ThemeProvider>
        </ApolloHooksProvider>
      </ApolloProvider>
    </Provider>
  )
}

export default wrapRootElement

module.exports = [{
      plugin: require('/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/codebuild/output/src964637455/src/bitbucket.org/teamgeek/sis-admin/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import clsx from "clsx"
import PropTypes from "prop-types"
import React from "react"

import { Paper, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

const Tile = ({ title, className, children, ...props }) => {
  const classes = useStyles()
  return (
    <Paper className={clsx(classes.paper, className)} {...props}>
      {title ? (
        <Typography className={classes.title} component="h1" variant="h5">
          {title}
        </Typography>
      ) : null}
      {children}
    </Paper>
  )
}

Tile.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
}

export default Tile

/**
 * @flow
 */

import { navigate } from "gatsby"
import { useEffect } from "react"

const Home = () => {
  useEffect(() => {
    navigate("/app")
  }, [])

  return null
}

export default Home

import PropTypes from "prop-types"
import React from "react"

import MuiTextField from "@material-ui/core/TextField"

const TextField = ({ field, form, normalize = v => v, ...props }) => {
  const error = form.errors[field.name] && form.touched[field.name]

  const handleBlur = () => {
    form.setFieldTouched(field.name, true)
  }

  const handleChange = event => {
    const value = normalize(event.target.value)
    form.setFieldValue(field.name, value)
  }

  return (
    <MuiTextField
      error={error}
      fullWidth
      helperText={error ? form.errors[field.name] : ""}
      margin="normal"
      onBlur={handleBlur}
      onChange={handleChange}
      value={field.value}
      variant="outlined"
      {...props}
    />
  )
}

TextField.propTypes = {
  ...MuiTextField.propTypes,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  normalize: PropTypes.func,
}

export default TextField
